import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import { userRoleSelector, userSelector } from '_modules/authentication/selectors'
import { USER_ROLES } from '_utils/user'
import { menuItemsSelector } from '_/modules/menu-items/selector'

import humps from 'humps'
import { useToast } from './use-toast'
import { FINANCE_ROUTES, ROUTES, WIDGETS_ROUTE } from '_/utils/constants'
import { PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const useRolePermission = () => {
  const userRole = useSelector(userRoleSelector)
  const user = useSelector(userSelector)
  const userMenuItems = useSelector(menuItemsSelector)
  const { pathname } = useLocation()
  const userMenuItemsPath = userMenuItems?.find(item => pathname.includes(item.path))
  const permissionName = humps.camelize(userMenuItemsPath?.permissionName ?? '')
  const { showToast } = useToast()

  const activeProfileCheck = useCallback(
    rolesToCompare => {
      if (!user?.activeProfile) {
        return false
      }

      return rolesToCompare.includes(user?.activeProfile)
    },
    [user]
  )

  const checkPathPermission = useCallback(
    path => {
      const menuItemsPath = userMenuItems?.find(item => path === item.path)

      const permissionPathName = humps.camelize(menuItemsPath?.permissionName ?? '')

      if (menuItemsPath && !menuItemsPath?.permissionName) {
        return true
      }

      return (
        (menuItemsPath &&
          user?.permissions?.[permissionPathName]?.find(
            item => item === PERMISSIONS_ACTIONS.READ
          )) ??
        false
      )
    },
    [userMenuItems, user]
  )

  const checkUserPermission = useCallback(
    ({ permission, permissionAction, functionAccess, profilesDenied = [] }) => {
      if (profilesDenied.length > 0 && profilesDenied.includes(user?.activeProfile)) {
        return showToast({
          type: 'error',
          message: 'Você não tem permissão para esta ação.',
        })
      }

      const permissionActions = Array.isArray(permissionAction)
        ? permissionAction
        : [permissionAction]

      if (permissionActions.some(action => user?.permissions?.[permission]?.includes(action))) {
        return functionAccess()
      }

      return showToast({
        type: 'error',
        message: 'Você não tem permissão para esta ação.',
      })
    },
    [userMenuItems, user]
  )

  const checkIfUserDoesNotPermission = useCallback(
    (permission, permissionAction, message = null) => {
      const permissionActions = Array.isArray(permissionAction)
        ? permissionAction
        : [permissionAction]

      const titleMessage = message ?? 'Você não tem permissão para esta ação.'

      if (!permissionActions.some(action => user?.permissions?.[permission]?.includes(action))) {
        if (message !== undefined) {
          showToast({
            type: 'error',
            message: titleMessage,
          })
        }
        return true
      }
      return false
    },
    [userMenuItems, user]
  )

  const isApprover = useMemo(
    () => activeProfileCheck([USER_ROLES.CS_APPROVER]),
    [activeProfileCheck]
  )
  const isManager = useMemo(() => activeProfileCheck([USER_ROLES.CS_MANAGER]), [activeProfileCheck])
  const isTradesman = useMemo(
    () => activeProfileCheck([USER_ROLES.TRADESMAN]),
    [activeProfileCheck]
  )
  const isAdmin = useMemo(() => activeProfileCheck([USER_ROLES.REFERA]), [activeProfileCheck])
  const isSAAS = useMemo(() => activeProfileCheck([USER_ROLES.SAAS]), [activeProfileCheck])
  const isAdminSAAS = useMemo(() => user?.permissions?.isAdmin, [user?.permissions])
  const isCsRefera = useMemo(() => activeProfileCheck([USER_ROLES.CS_REFERA]), [activeProfileCheck])
  const isFinance = useMemo(() => activeProfileCheck([USER_ROLES.CS_FINANCE]), [activeProfileCheck])
  const isLessee = useMemo(() => activeProfileCheck([USER_ROLES.LESSEE]), [activeProfileCheck])
  const isIntermediary = useMemo(
    () =>
      activeProfileCheck([USER_ROLES.CS_APPROVER, USER_ROLES.CS_MANAGER, USER_ROLES.INTERMEDIARY]),
    [activeProfileCheck]
  )

  const checkIfUserHasARole =
    [
      isApprover,
      isManager,
      isTradesman,
      isAdmin,
      isAdminSAAS,
      isCsRefera,
      isFinance,
      isLessee,
      isIntermediary,
    ].filter(Boolean).length > 0

  const checkPathPermissionByRole = useMemo(() => {
    // This will remove the ids from the agency/providers routes
    const newPathname = `/${pathname.split('/')[1]}`
    let unblockedTabs = []

    if (isApprover || isManager || isIntermediary || isSAAS) {
      unblockedTabs = [
        ROUTES.MANAGE_ORDERS,
        ROUTES.NEW_MANAGE_ORDERS,
        ROUTES.TRADESMAN,
        ROUTES.SERVICE_ORDER,
        WIDGETS_ROUTE.WIDGETS,
        ROUTES.HELP_REQUESTS,
        ROUTES.REPORTS,
        ROUTES.CLASSIFICATION_FORM,
        ROUTES.LANDING,
        ROUTES.LANDING_PAGE_B2C,
        ROUTES.LANDING_PAGE_B2C_AGENCY,
        ROUTES.FINANCE_MANAGER,
        ROUTES.NEW_SERVICE_LOGGED,
        ROUTES.AGENCY,
        ROUTES.NOT_FOUND,
        ROUTES.GROUP,
        ROUTES.REFERA_COVERAGE,
        ROUTES.MANAGE_PROVIDERS,
        ROUTES.MENSAGERIA,
        ROUTES.CREATE_PROVIDER,
        ROUTES.PROVIDER,
        ROUTES.SERVICE_ORDERS,
      ]
    }

    if (isTradesman) {
      unblockedTabs = [
        ROUTES.TRADESMAN,
        ROUTES.HELP_REQUESTS,
        ROUTES.NEW_ORDERS,
        ROUTES.DELAYED_ORDERS,
        ROUTES.INSTALLMENTS_RECEIPTS,
        ROUTES.NOT_FOUND,
        ROUTES.DETAIL_SERVICE_ORDER,
        ROUTES.SEND_RATING_LINK,
        ROUTES.LANDING,
        ROUTES.LANDING_PAGE_B2C,
        ROUTES.NF_FORM,
        ROUTES.NEW_SERVICE_LOGGED,
        ROUTES.BUDGET_TEMPLATE,
        ROUTES.CONDOLIVRE_CONTRACT,
        ROUTES.BUDGET_VIEW,
        ROUTES.SERVICE_ORDERS,
      ]
    }

    if (isAdmin || isFinance) {
      unblockedTabs = [
        ...Object.values(ROUTES),
        ...Object.values(FINANCE_ROUTES),
        WIDGETS_ROUTE.WIDGETS,
        ROUTES.NEW_SERVICE_LOGGED,
        ROUTES.LANDING_PAGE_B2C,
        ROUTES.GROUP,
        ROUTES.SERVICE_ORDERS,
      ]
    }

    if (isSAAS) {
      unblockedTabs = [
        ...unblockedTabs,
        ROUTES.SEND_RATING_LINK,
        ROUTES.BUDGET_VIEW,
        ROUTES.SERVICE_ORDERS,
      ]
    }

    return unblockedTabs?.includes(newPathname)
  }, [
    isAdmin,
    isFinance,
    isApprover,
    isManager,
    isTradesman,
    isIntermediary,
    pathname,
    checkIfUserHasARole,
  ])

  const hasPermissionToAccessPage = useMemo(() => {
    if (!user?.id || !checkIfUserHasARole) {
      return undefined
    }

    if (!checkPathPermissionByRole) {
      return false
    }

    if (!userMenuItemsPath?.permissionName) {
      return true
    }

    return user?.permissions?.[permissionName]?.find(item => item === PERMISSIONS_ACTIONS.READ)
  }, [user, checkPathPermissionByRole, checkIfUserHasARole, userMenuItemsPath])

  return {
    role: userRole,
    isCsRefera,
    isApprover,
    isManager,
    isAdmin,
    isSAAS,
    isAdminSAAS,
    isFinance,
    isLessee,
    isTradesman,
    isIntermediary,
    hasPermissionToAccessPage,
    checkPathPermission,
    checkUserPermission,
    checkIfUserDoesNotPermission,
  }
}

export default useRolePermission
