import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'

import {
  currentServiceOrderSelector,
  getButtonsActionByNameSelector,
} from '_modules/service-orders/selectors'
import { getStepStatusLogSelector } from '_modules/budget/selectors'
import { getButtonsAction } from '_/modules/service-orders/actions'
import { getStepStatusLog } from '_/modules/budget/actions'
import { CanDoButtonAction } from '_/utils/can-do-button-action'

const useCanDoButtonAction = ({ nameButton }) => {
  const { budgetId } = useParams()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const buttonAction = useSelector(getButtonsActionByNameSelector(nameButton))
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (stepStatusLog.length === 0 && serviceOrder?.id && budgetId) {
      dispatch(getStepStatusLog(serviceOrder?.id, budgetId))
    }

    if (!buttonAction) {
      dispatch(getButtonsAction())
    }
  }, [budgetId, serviceOrder, buttonAction])

  return CanDoButtonAction({ nameButton })
}

export default useCanDoButtonAction
